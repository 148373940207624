.autocomplete-dropdown {
  width: 100%;
}
.autocomplete-dropdown-row {
  white-space: initial;
  overflow-wrap: break-word;
}

.search-query-match-highlight em {
  font-style: normal;
  color: #ffffff;
  background: #2196f3;
  border-radius: 2px;
  padding: 1px 5px;
}
