.header {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white !important;
  padding-top: 15px !important;
}

.header-full {
  padding-left: 0px !important;
}

.header-collapsed {
  padding-left: 0px !important;
}
